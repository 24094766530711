define("itarp-business-application/routes/profile/work-arrangement", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProfileWorkArrangementRoute extends _route.default {
    buildRouteInfoMetadata() {
      return {
        title: 'Work Arrangement',
        ui: {
          sidebarHidden: true,
          padding: {
            top: 0
          }
        }
      };
    }

    async model() {
      const user = this.modelFor('profile');

      if (user && user.profiles) {
        ['educations', 'experiences', 'certifications', 'skills', 'tools', 'links', 'industries'].forEach(type => {
          if (user.profiles.get(type)) {
            (0, _object.set)(user.profiles, type, user.profiles.get(type).toArray().filter(d => d.id !== null));
          }
        });
      }

      return user;
    }

    setupController(controller, model) {
      super.setupController(controller, model);

      if (!model.profiles.get('workAvailable')) {
        model.profiles.set('workAvailable', false);
      } // setting default currency as $ by default since we are not supporting other types atm


      model.profiles.set('hourlyRateCurrency', '$');
      model.profiles.set('workPreference', model.profiles.get('contractor') || model.profiles.get('fullTimePreference') || model.profiles.get('partTimePreference') || model.profiles.get('anyPreference'));
      controller.set('errors', []);
      controller.set('fieldError', {});
      window.scrollTo(0, 0);
    }

  }

  _exports.default = ProfileWorkArrangementRoute;
});