define("itarp-opportunity-ui/routes/details", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "@glimmer/tracking"], function (_exports, _route, _service, _rsvp, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsRoute = (_dec = (0, _service.inject)('current-user'), (_class = class DetailsRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "pageTitle", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        expertApplies: {
          refreshModel: true
        }
      });
    }

    buildRouteInfoMetadata() {
      return {
        title: this.pageTitle,
        ui: {
          padding: {
            top: 0
          }
        }
      };
    }

    async model(params) {
      const user = await this.currentUser.information;
      const applications = await this.store.query('application', {
        filter: {
          resource_id: params.id,
          resource_type: 'opportunity',
          user_id: user.id
        }
      });
      const application = applications.get('firstObject');
      const opportunity = await this.store.findRecord('opportunity', params.id);
      const questionnaires = await this.store.query('questionnaire', {
        filter: {
          resource_id: params.id,
          resource_type: 'opportunity'
        }
      });
      const invitationsForUser = await this.store.query('invitation', {
        filter: {
          user_id: user.id,
          resource_type: 'opportunity',
          resource_id: params.id
        },
        adapterOptions: {
          invitation: true
        }
      });
      const reasonParams = {
        reason_type: 'decline_opportunity_invitation'
      };
      const primaryReasons = await this.store.query('reason', reasonParams);

      if (opportunity.get('questions')) {
        for (const item of opportunity.get('questions').toArray()) {
          const question = questionnaires.findBy('id', item.get('questionnaire_id'));
          if (question) item.questionnaire = question;
        }
      }

      const questions = opportunity.questions || [];
      return (0, _rsvp.hash)({
        opportunity,
        questions,
        application: application || this.store.createRecord('application'),
        isApplied: application ? true : false,
        expertApplies: params.expertApplies === 'true',
        invitation: invitationsForUser.get('firstObject'),
        primaryReasons,
        user,
        favorites: this.store.query('user-favorite', {
          filter: {
            user_id: user.id,
            resource_type: 'opportunity'
          }
        })
      });
    }

    setupController(controller, model) {
      var _this$currentUser, _this$currentUser$adT, _this$currentUser$adT2;

      super.setupController(controller, model);
      this.pageTitle = model.opportunity.jobTitle;

      if (model.user.profiles) {
        const resume = model.user.profiles.get('resume');
        const linkedinUrl = model.user.profiles.get('linkedinUrl');

        if (resume) {
          model.application.set('resume', resume);
        }

        if (linkedinUrl && !model.application.linkedinUrl) {
          model.application.set('linkedinUrl', linkedinUrl);
        }
      }

      model.application.set('isInvited', model.invitation !== undefined);
      controller.set('opportunity', model.opportunity);
      controller.set('opportunityId', model.opportunity.id);
      controller.set('application', model.application);
      controller.set('isApplied', model.isApplied);
      controller.set('expertApplies', model.expertApplies);
      controller.set('isApplyOpen', !model.isApplied && model.expertApplies);
      controller.set('invitation', model.invitation);
      controller.set('primaryReasons', model.primaryReasons.filterBy('active', true));
      controller.set('questions', model.questions);
      controller.set('user', model.user);
      controller.set('companyId', (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : (_this$currentUser$adT = _this$currentUser.adTokenService) === null || _this$currentUser$adT === void 0 ? void 0 : (_this$currentUser$adT2 = _this$currentUser$adT.tokenInformation) === null || _this$currentUser$adT2 === void 0 ? void 0 : _this$currentUser$adT2.extension_company_id);
      controller.set('activeTab', 'overview');

      if (model.favorites && model.favorites.length > 0) {
        controller.set('userFavorites', model.favorites.get('firstObject').resourceIds);
      } else {
        controller.set('userFavorites', []);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pageTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class));
  _exports.default = DetailsRoute;
});