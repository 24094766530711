define("itarp-opportunity-designer/routes/chat/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChatMessagesRoute extends _route.default {
    buildRouteInfoMetadata() {
      return {
        title: 'Chat',
        ui: {
          sidebarHidden: true,
          padding: {
            top: 0
          }
        }
      };
    }

  }

  _exports.default = ChatMessagesRoute;
});