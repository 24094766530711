define("itarp-talentcloud-ui/engine", ["exports", "ember-engines/engine", "ember-load-initializers", "itarp-talentcloud-ui/resolver", "itarp-talentcloud-ui/config/environment"], function (_exports, _engine, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    modulePrefix
  } = _environment.default;

  class ItarpTalentcloudUiEngine extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", modulePrefix);

      _defineProperty(this, "Resolver", _resolver.default);

      _defineProperty(this, "dependencies", {
        services: ['layout', 'workflows', 'toast', 'accountAccess'],
        externalRoutes: ['expertise', 'autofill', 'basic-info', 'work-arrangement', 'dynamic-route', 'expert-opportunity']
      });
    }

  }

  (0, _emberLoadInitializers.default)(ItarpTalentcloudUiEngine, modulePrefix);
  var _default = ItarpTalentcloudUiEngine;
  _exports.default = _default;
});